import React, { useState, useEffect } from "react"
import { Form, FormGroup, Label, Input, Button } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SingleCourseSEO from "./SingleCourseSEO"
import BundleCourseSEO from "./BundleCourseSEO"

const SEOKeywords = () => {

    const [isBundle, setIsBundle] = useState(false)

    return (
        <div className="orderDiv">
            <h3>SEO Keywords</h3>
            <button className="btn btn-danger" onClick={() => setIsBundle(false)}>Single Course SEO</button>

            <button className="btn btn-secondary ms-2" onClick={() => setIsBundle(true)}>Bundle Course SEO</button>

            {isBundle ? <BundleCourseSEO /> :
                <SingleCourseSEO />}

            {/* <Form>
                <FormGroup>
                    <Label for="SelectCourseTitle">Course Title</Label>
                    <Input
                        name="courseTitle"
                        type="select"
                        onChange={e => setCourseId(e.target.value)}
                    >
                        {courseList?.map(course => {
                            return (
                                <>
                                    <option value="" hidden>
                                        Select
                                    </option>
                                    <option key={course?._id} value={course._id}>{course.title}</option>
                                </>
                            )
                        })}
                    </Input>
                </FormGroup>
            </Form>
            {seoData ? (
                <div style={{ marginTop: "15px" }}>
                    <Label for="SelectCourseTitle">Seo Title</Label>
                    <Input
                        onChange={e => {
                            setSEOTitle(e.target.value)
                        }}
                        value={SEOTitle}
                        placeholder="Enter seo title here"
                    />
                    <Label style={{ marginTop: "15px" }} for="SelectCourseDescription">Seo Description</Label>
                    <Input
                        type="textarea"
                        onChange={e => {
                            setSEOMetaDescription(e.target.value)
                        }}
                        value={SEOMetaDescription}
                        maxLength="620"
                        rows="3"
                        placeholder="Enter seo description here"
                    />
                    <Label style={{ marginTop: "15px" }} for="SelectCourseTitle">Seo Keywords</Label>
                    <Input
                        type="textarea"
                        onChange={e => {
                            setSeoKeywords(e.target.value)
                        }}
                        value={seoKeywords}
                        maxLength="620"
                        rows="3"
                        placeholder="Enter seo keywords here"
                    />
                    <button className="btn btn-primary mt-2" onClick={handleSave}>Save</button>
                </div>
            ) : null} */}
        </div>
    )
}

export default SEOKeywords
