import React, { useState, useEffect } from "react"
import "./OrderList.css"
import { Form, FormGroup, Label, Input, Button } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const OrderList = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState()
  const [courseList, setCourseList] = useState([])
  const [courseId, setCourseId] = useState("")
  const [orderList, setOrderList] = useState([])
  const [payment, setPayment] = useState(null)

  const [state, setState] = useState({
    formData: {
      startingDate: "",
      endingDate: "",
      courseId: "",
      paymentDone: ""
    },
  })

  const getAllCourse = () => {
    fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setCourseList(data?.message)
      })
      .catch(err => console.log(err?.response?.data))
  }

  useEffect(() => {
    getAllCourse()
  }, [])

  useEffect(() => {
    const data = state.formData
    data.startingDate = startDate ? new Date(startDate).toISOString() : null
    data.endingDate = endDate ? new Date(endDate).toISOString() : null
    data.courseId = courseId ? courseId : null
    data.paymentDone = payment ? payment : null
    // data.courseId = '6305c77c12f00e24de1fd4d5'
    console.log(data)

    // https://backend.ourprofessors.com/api/order/course/${courseId}
    // ${process.env.REACT_APP_URL}/api/order/course/${courseId}
    if (data.courseId) {
      fetch(`https://backend.ourprofessors.com/api/order/course/${courseId}`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(res => res?.json())
        .then(data => {
          setOrderList(data)
          console.log(data)
          if (data?.success) {
            // toastr.success(data?.message, "Order")
            console.log("courseId", data)
          } else {
            // toastr.error(data?.message, "Order")
          }
        })
        .catch(err => toastr.error(err?.response?.data?.message, "Order"))
    } else {
      fetch(`https://backend.ourprofessors.com/api/order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(res => res?.json())
        .then(data => {
          console.log(data)
          setOrderList(data)
          if (data?.success) {
            // toastr.success(data?.message, "Order")
          } else {
            // toastr.error(data?.message, "Order")
          }
        })
        .catch(err => toastr.error(err?.response?.data?.message, "Order"))
    }
  }, [startDate, endDate, courseId, payment])

  return (
    <div className="orderDiv">
      <h3>Order List</h3>
      <Form>
        <FormGroup>
          <Label for="SelectCourseTitle">Course Title</Label>
          <Input
            name="courseTitle"
            type="select"
            onChange={e => setCourseId(e.target.value)}
          >
            {courseList?.map(course => {
              return (
                <>
                  <option key={course._id} value="" hidden>
                    Select
                  </option>
                  <option value={course._id}>{course.title}</option>
                </>
              )
            })}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label className="mt-3" for="exampleDate">
            Starting Date
          </Label>
          <Input
            id="exampleDate"
            name="startingDate"
            placeholder="date placeholder"
            type="date"
            onChange={e => setStartDate(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Label className="mt-3" for="exampleDate">
            Ending Date
          </Label>
          <Input
            id="exampleDate"
            name="endingDate"
            placeholder="date placeholder"
            type="date"
            onChange={e => setEndDate(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Label className="mt-3" for="exampleDate">
            Payment Done
          </Label>
          <Input
            name="payment"
            type="select"
            onChange={e => setPayment(e.target.value)}
          >
            <option value="" hidden>
              Select
            </option>
            <option value={true}>Payment Done Successfully</option>
            <option value={false}>Payment Failed</option>
          </Input>
        </FormGroup>
      </Form>
      {orderList ? (
        <div classname="mt-3 mt-5">
          <h4 className="mt-3">
            Total Order Found: {orderList?.data?.docs?.length}
          </h4>
          <h4 className="mt-3">
            Total Earning: {orderList?.data?.totalEarning}
          </h4>
          <div>
            <table className="table table-striped table-hover">
              <thead className="thead  tablehead">
                <tr>
                  <td scope="col">#</td>
                  <td scope="col">Course Name</td>
                  <td scope="col">Date</td>
                  <td scope="col">User</td>
                  <td scope="col">Email</td>
                  <td scope="col">Phone</td>
                  <td scope="col">Price</td>
                  <td scope="col">Trainer %</td>
                  <td scope="col">Trainer Amount</td>
                  <td scope="col">TrxId</td>
                  <td scope="col">Payment</td>
                </tr>
              </thead>

              <tbody className="tablebody">
                {orderList?.data?.docs?.map((order, index) => {
                  return (
                    <tr key={order._id}>
                      <td data-label="No" className="align-middle">
                        {index + 1}
                      </td>
                      <td data-label="Course Name" className="align-middle">
                        {order.cartItems}
                      </td>
                      <td
                        data-label="Transaction Date"
                        className="align-middle"
                      >
                        {order.createdAt.slice(0, 10)}
                      </td>
                      <td data-label="Customer Name" className="align-middle">
                        {order.customerInfo.cusName}
                      </td>
                      <td data-label="Customer Email" className="align-middle">
                        {order.customerInfo.cusEmail}
                      </td>
                      <td
                        data-label="Customer Phone No"
                        className="align-middle"
                      >
                        {order.customerInfo.cusPhone}
                      </td>
                      <td data-label="Amount" className="align-middle">
                        {order.totalAmount}
                      </td>
                      <td data-label="Trainer %" className="align-middle">{order?.trainerPercentage}</td>
                      <td data-label="Trainer Amount" className="align-middle">{order?.trainerAmount}</td>
                      <td data-label="TrxId" className="align-middle">
                        {order.transactionId}
                      </td>
                      <td data-label="Payment" className="align-middle">
                        {order.paymentDone ? "Success" : "Failed"}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default OrderList
