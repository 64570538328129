import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"

import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"
import UserProfile from "../pages/Authentication/user-profile"
// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Student
import Student from "../pages/Students/index"
import GetStudentById from "../pages/GetStudentById"

// Student
import Courses from "../pages/Courses/index"
import AddNewCourse from "../pages/Courses/AddNewCourse"
import EditCourse from "../pages/Courses/EditCourse"
import FeaturedCourses from "../pages/FeaturedCourse/Index"
import EditFeaturedCourses from "../pages/FeaturedCourse/FeaturedCourse"

//Blog
import Blogs from "../pages/Blog/index"
import AddNewBlog from "../pages/Blog/AddNewBlog"
import EditBlog from "../pages/Blog/EditBlog"

//Admin
import Admins from "../pages/Admin/index"
import AddAdmins from "../pages/Admin/AddAdmin"

//Instructors
import Instructors from "../pages/Instructors/index"
import AddNewInstructor from "../pages/Instructors/AddNewInstructor"

// Category
import Category from "../pages/Category/Category"
import AddNewCategory from "../pages/Category/AddNewCategory"

// Book
import Book from "../pages/Book/index"
import AddNewBook from "../pages/Book/AddNewBook"
import EditBook from "../pages/Book/EditBook"

//Bundle Courses
import BundleCourses from "../pages/BundleCourses/BundleCourses"
import AddNewBundleCourse from "../pages/BundleCourses/AddNewBundleCourse"
import EditBundleCourse from "../pages/BundleCourses/EditCourse"
import EditCategory from "pages/Category/EditCategory"
import AddNewFeaturedCourse from "pages/FeaturedCourse/AddNewFeaturedCourse"

import EnrolledCourses from "../pages/EnrolledCourse/index"
import ManualPayment from "pages/ManualPayment"
import Coupons from "pages/Coupon/Coupons"
import AddNewCoupon from "pages/Coupon/AddNewCoupon"
import EditCoupon from "pages/Coupon/EditCoupon"
import Notice from "pages/Notice/Notices"
import AddNotice from "pages/Notice/AddNewNotice"
import EditNotice from "pages/Notice/EditNotice"

import DynamicData from "pages/DynamicData/index"
import Quiz from "pages/Quiz/Quiz"
import OrderList from "pages/OrderList/OrderList"
import Certificate from "pages/CertificateCorrection/CertificateCorrection"
import CreateSeminar from "pages/Seminar/CreateSeminar"
import SeminarIndex from "pages/Seminar/SeminarIndex"
import SeminarEdit from "pages/Seminar/SeminarEdit"
import SeminarStudent from "pages/SeminarStudent/SeminarStudent"
import SupportList from "pages/Support/SupportList"
import SupportDetails from "pages/Support/SupportDetails"
import AffiliateList from "pages/Affiliate/AffiliateList"
import AffiliatePayment from "pages/Affiliate/AffiliatePayment"
import Subscribers from "pages/Subscribers/Subscribers"
import ScholarShipStudents from "pages/ScholarShipStudents/ScholarShipStudents"
import ManualEnrollPayment from "pages/ManualEnrollPayment/ManualEnrollPaymentCreate"
import ManualEnrollPaymentCreate from "pages/ManualEnrollPayment/ManualEnrollPaymentCreate"
import ManualEnrollPaymentIndex from "pages/ManualEnrollPayment/ManualEnrollPaymentIndex"
import ScholarShipDetails from "pages/ScholarShipStudents/ScholarShipDetaila"
import SEOKeywords from "pages/SEO/SEO"
import TeacherRequest from "pages/TeacherRequest/TeacherRequest"
import BundleStudent from "pages/BundleStudent/BundleStudent"
const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // Student
  { path: "/students", component: Student },
  { path: "/bundle-courses-students", component: BundleStudent },
  { path: "/student/:id", component: GetStudentById },

  // Instructor
  { path: "/instructors", component: Instructors },
  { path: "/instructors/add/new", component: AddNewInstructor },
  { path: "/instructor/request", component: TeacherRequest },

  // Category
  { path: "/category", component: Category },
  { path: "/category/add/new", component: AddNewCategory },
  { path: "/category/edit/:id", component: EditCategory },
  // Course
  { path: "/courses", component: Courses },
  { path: "/courses/add/new", component: AddNewCourse },
  { path: "/courses/edit/:id", component: EditCourse },
  { path: "/seo-keywords", component: SEOKeywords },

  // Quiz
  { path: "/quiz", component: Quiz },

  //Order List
  { path: "/orderList", component: OrderList },

  //Certificate Correction
  { path: "/certificate", component: Certificate },

  //Seminar
  { path: "/seminar", component: SeminarIndex },
  { path: "/seminar/add/new", component: CreateSeminar },
  { path: "/seminars/edit/:seminarId", component: SeminarEdit },

  //Seminar Student
  { path: "/seminarStudent", component: SeminarStudent },

  //Support Ticket
  { path: "/support", component: SupportList },
  { path: "/supportDetails/:supportTicketId", component: SupportDetails },

  //affiliate
  { path: "/affiliateList", component: AffiliateList },
  { path: "/affiliate/:affiliateId", component: AffiliatePayment },

  //Blog
  { path: "/blogs", component: Blogs },
  { path: "/blogs/add/new", component: AddNewBlog },
  { path: "/blogs/edit/:id", component: EditBlog },

  { path: "/featured-courses", component: FeaturedCourses },
  { path: "/featured-courses/edit/:id", component: EditFeaturedCourses },
  { path: "/featured-courses/add/new", component: AddNewFeaturedCourse },

  // Notice
  { path: "/notice", component: Notice },
  { path: "/add-notice", component: AddNotice },
  { path: "/edit-notice/:id", component: EditNotice },

  //* Dynamic data route

  {
    path: "/dynamic-data",
    component: DynamicData,
  },

  //** Admin Route */

  { path: "/admins", component: Admins },
  { path: "/add-admin", component: AddAdmins },

  //** Bundle Courses */

  { path: "/bundle-courses", component: BundleCourses },
  { path: "/bundle-courses/add/new", component: AddNewBundleCourse },
  { path: "/bundle-courses/edit/:id", component: EditBundleCourse },
  { path: "/enrolled-courses", component: EnrolledCourses },
  { path: "/coupons", component: Coupons },
  { path: "/add-coupon", component: AddNewCoupon },
  { path: "/edit-coupon/:id", component: EditCoupon },

  //Book
  { path: "/book", component: Book },
  { path: "/book/add/new", component: AddNewBook },
  { path: "/book/edit/:id", component: EditBook },

  { path: "/profile", component: UserProfile },

  // Payment
  { path: "/manual-payment", component: ManualPayment },
  { path: "/manual-enroll-payment", component: ManualEnrollPaymentIndex },
  {
    path: "/manual-enroll-payment/add/new",
    component: ManualEnrollPaymentCreate,
  },
  { path: "/scholarship-student", component: ScholarShipStudents },
  { path: "/scholarship-details/:id", component: ScholarShipDetails },

  //
  { path: "/subscribers", component: Subscribers },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
