import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  CardSubtitle,
  Label,
  Input,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
// React router dom
import { useHistory } from "react-router"
import FBEditor from "components/Editor/Editor"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { getInfo, updateInfo } from "actions/information"

import Dropzone from "react-dropzone"
import { imageUpload } from "actions/imageUpload"

const Category = props => {
  const [information, setInformation] = useState(null)
  const [uploadStatus, setUploadStatus] = useState(false)
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [visitors, setVisitors] = useState(0)
  const [header, setHeader] = useState("")
  const [subHeader, setSubHeader] = useState("")
  const [buttonText, setButtonText] = useState("")
  const [redirectURL, setRedirectURL] = useState("")

  const [popUpNoticeImage, setPopUpNoticeImage] = useState(null)
  const [popUpNoticeImageError, setPopUpNoticeImageError] = useState(null)
  useEffect(() => {
    getInfo()
      .then(r => r.json())
      .then(res => {
        setInformation(res?.data)
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if (information) {
      console.log(information)
      setEmail(information?.information?.email)
      setPhone(information?.information?.phone)
      setVisitors(information?.information?.visitors)
      setHeader(information?.information?.title)
      setSubHeader(information?.information?.subTitle)
      setButtonText(information?.information?.buttonText)
      setRedirectURL(information?.information?.redirectURL)
      setPopUpNoticeImage(information?.information?.homeBannerImage)
    }
  }, [information])

  const handleUpdate = () => {
    const data = {
      email,
      phone,
      visitors,
      title: header,
      subTitle: subHeader,
      homeBannerImage: popUpNoticeImage,
      buttonText: buttonText,
      redirectURL: redirectURL,
    }
    updateInfo(data)
      .then(r => r.json())
      .then(res => {
        if (res) {
          toastr.success("Data saved successful!")
        }
      })
      .catch(err => {
        if (err) {
          toastr.error("Something went wrong")
        }
      })
  }

  function handleAcceptedCardFiles(files, setError, setImage, message) {
    setError(null)
    const extension = files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setImage(data?.Location)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              setUploadStatus(false)

              toastr.error(data?.message, "Course Image")
            } else {
              setImage(data?.Location)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Course Image")
          } else {
            toastr.error(err?.response?.data?.message, "Course Image")
          }
        })
    } else {
      setError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dynamic Data")}
            breadcrumbItem={props.t("Dynamic Data")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle>Dynamic Data</CardTitle>

                      <div>
                        <label htmlFor="">Contact us emails</label>
                        <FBEditor
                          htmlData={email}
                          setHtmlData={setEmail}
                          prevData={
                            information !== null
                              ? information?.information?.email !== null
                                ? information?.information?.email
                                : ""
                              : ""
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <label htmlFor="">Footer Phone Numbers</label>
                        <FBEditor
                          htmlData={phone}
                          setHtmlData={setPhone}
                          prevData={
                            information !== null
                              ? information?.information?.phone !== null
                                ? information?.information?.phone
                                : ""
                              : ""
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <label htmlFor="">Total Visitor</label>
                        <input
                          placeholder="Enter total visitor"
                          className="form-control"
                          type="number"
                          name=""
                          id=""
                          value={visitors}
                          onChange={e => setVisitors(e.target.value)}
                        />
                      </div>

                      <div className="mt-2">
                        <label htmlFor="">Popup notice image</label>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedCardFiles(
                              acceptedFiles,
                              setPopUpNoticeImageError,
                              setPopUpNoticeImage,
                              "Pop-up Notice Image"
                            )
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input
                                  name="notice-image-popup"
                                  {...getInputProps()}
                                />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        {popUpNoticeImageError && (
                          <span
                            style={{
                              display: "block",
                              marginTop: "10px",
                              color: "red",
                            }}
                          >
                            {popUpNoticeImageError}
                          </span>
                        )}
                        {popUpNoticeImage && (
                          <div className="mt-2">
                            <img
                              style={{
                                width: "200px",
                                height: "150px",
                              }}
                              src={popUpNoticeImage}
                              alt="Card image"
                            />

                            <div className="d-flex justify-content-start mt-1">
                              {" "}
                              <button
                                onClick={() => setPopUpNoticeImage(null)}
                                className="btn btn-danger"
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="my-2">
                        <Label htmlFor="formrow-firstname-Input">Title</Label>
                        <Input
                          name="header-redirect"
                          value={header}
                          onChange={e => setHeader(e.target.value)}
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                        />
                      </div>

                      <div className="my-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Subtitle
                        </Label>
                        <Input
                          name="popup-redirect"
                          value={subHeader}
                          onChange={e => setSubHeader(e.target.value)}
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                        />
                      </div>

                      <div className="my-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Button Text
                        </Label>
                        <Input
                          name="popup-redirect"
                          value={buttonText}
                          onChange={e => setButtonText(e.target.value)}
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                        />
                      </div>
                      <div className="my-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Redirect URL
                        </Label>
                        <Input
                          name="popup-redirect"
                          value={redirectURL}
                          onChange={e => setRedirectURL(e.target.value)}
                          type="text"
                          className="form-control"
                          id="formrow-firstname-Input"
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          onClick={handleUpdate}
                          className="btn btn-primary"
                        >
                          Update
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Category)
