import { getSingleNotices, updateSingleNotices } from "actions/notice"
import FBEditor from "components/Editor/Editor"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import MetaTags from "react-meta-tags"
import { useHistory, useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Label, Input, Row } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { imageUpload } from "actions/imageUpload"

//redux

const EditNotice = props => {
  const { id } = useParams()
  const [noticeDetails, setNoticeDetails] = useState(null)
  const [text, setText] = useState("")
  const [active, setActive] = useState(true)

  const [image, setImage] = useState(null)
  const [uploadStatus, setUploadStatus] = useState(false)
  const [dekstopNoticeImage, setDekstopNoticeImage] = useState(null)
  const [mobileNoticeImage, setMobileNoticeImage] = useState(null)
  const [popUpNoticeImage, setPopUpNoticeImage] = useState(null)
  const [dekstopNoticeImageError, setDekstopNoticeImageError] = useState(null)
  const [mobileNoticeImageError, setMobileNoticeImageError] = useState(null)
  const [popUpNoticeImageError, setPopUpNoticeImageError] = useState(null)

  const [imageNotice, setImageNotice] = useState('none')
  const [popupNotice, setPopupNotice] = useState(false)
  const [headerNoticeRedirectURL, setHeaderNoticeRedirectURL] = useState("")
  const [popupNoticeRedirectURL, setPopupNoticeRedirectURL] = useState("")

  const msg = "Already one notice is visible, please turn off the notice first"
  const history = useHistory()

  useEffect(() => {
    getSingleNotices(id)
      .then(r => r.json())
      .then(data => {
        const details = data?.message
        setNoticeDetails(details)


        setDekstopNoticeImage(details?.desktopImagURL)
        setMobileNoticeImage(details?.mobileImageURL)
        setHeaderNoticeRedirectURL(details?.imageRedirectURL)
        setImageNotice(details?.isImageVisible)
        setPopUpNoticeImage(details?.popupImageURL)
        setPopupNoticeRedirectURL(details?.popupImageRedirectURL)
        setPopupNotice(details?.isPopupImageVisible)
      })
      .catch(err => console.log(err))
  }, [id])

  useEffect(() => {
    setText(noticeDetails?.text ? noticeDetails?.text : "")
    setActive(noticeDetails?.visible)
  }, [noticeDetails])

  const handleSubmit = e => {
    e.preventDefault()

    const body = {
      text,
      visible: active,
      desktopImagURL: dekstopNoticeImage,
      mobileImageURL: mobileNoticeImage,
      imageRedirectURL: headerNoticeRedirectURL,
      isImageVisible: imageNotice,
      popupImageURL: popUpNoticeImage,
      popupImageRedirectURL: popupNoticeRedirectURL,
      isPopupImageVisible: popupNotice
    }
    updateSingleNotices(id, body)
      .then(res => res?.json())
      .then(data => {
        if (!noticeDetails?.visible && data?.success && data?.message === msg) {
          toastr.error(data?.message, "Notice")
        } else {
          if (data?.success) {
            toastr.success("Notice updated!!", "Notice")
            history.push("/notice")
          } else {
            toastr.error(data?.message?.message, "Notice")
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleBodyChange = e => {
    setText(e)
  }

  function handleAcceptedCardFiles(files, setError, setImage, message) {
    setError(null)
    const extension = files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setImage(data?.Location)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              setUploadStatus(false)

              toastr.error(data?.message, "Course Image")
            } else {
              setImage(data?.Location)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Course Image")
          } else {
            toastr.error(err?.response?.data?.message, "Course Image")
          }
        })
    } else {
      setError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Notice")}
            breadcrumbItem={props.t("Edit notice")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Notice Text
                              </Label>
                              <FBEditor
                                htmlData={text}
                                setHtmlData={setText}
                                prevData={
                                  noticeDetails?.text ? noticeDetails?.text : ""
                                }
                              />
                            </div>

                            <div className="mt-2">
                              <label htmlFor="">Notice image on header for dekstop</label>
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  handleAcceptedCardFiles(acceptedFiles, setDekstopNoticeImageError, setDekstopNoticeImage, "Dekstop Notice Image")
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick mt-2"
                                      {...getRootProps()}
                                    >
                                      <input name='notice-image-dekstop'  {...getInputProps()} />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>Drop files here or click to upload.</h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              {dekstopNoticeImageError && (
                                <span
                                  style={{
                                    display: "block",
                                    marginTop: "10px",
                                    color: "red",
                                  }}
                                >
                                  {dekstopNoticeImageError}
                                </span>
                              )}
                              {dekstopNoticeImage && (
                                <div className="mt-2">
                                  <img
                                    style={{
                                      width: "200px",
                                      height: "150px",
                                    }}
                                    src={dekstopNoticeImage}
                                    alt="Card image"
                                  />

                                  <div className="d-flex justify-content-start mt-1">
                                    {" "}
                                    <button
                                      onClick={() => setDekstopNoticeImage(null)}
                                      className="btn btn-danger"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>


                            <div className="mt-2">
                              <label htmlFor="">Mobile image on header for dekstop</label>
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  handleAcceptedCardFiles(acceptedFiles, setMobileNoticeImageError, setMobileNoticeImage, "Mobile Notice Image")
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick mt-2"
                                      {...getRootProps()}
                                    >
                                      <input name='notice-image-mobile'  {...getInputProps()} />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>Drop files here or click to upload.</h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              {mobileNoticeImageError && (
                                <span
                                  style={{
                                    display: "block",
                                    marginTop: "10px",
                                    color: "red",
                                  }}
                                >
                                  {mobileNoticeImageError}
                                </span>
                              )}
                              {mobileNoticeImage && (
                                <div className="mt-2">
                                  <img
                                    style={{
                                      width: "200px",
                                      height: "150px",
                                    }}
                                    src={mobileNoticeImage}
                                    alt="Card image"
                                  />

                                  <div className="d-flex justify-content-start mt-1">
                                    {" "}
                                    <button
                                      onClick={() => setMobileNoticeImage(null)}
                                      className="btn btn-danger"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="mt-2">
                              <label htmlFor="">Popup notice image</label>
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  handleAcceptedCardFiles(acceptedFiles, setPopUpNoticeImageError, setPopUpNoticeImage, "Pop-up Notice Image")
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick mt-2"
                                      {...getRootProps()}
                                    >
                                      <input name='notice-image-popup'  {...getInputProps()} />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>Drop files here or click to upload.</h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              {popUpNoticeImageError && (
                                <span
                                  style={{
                                    display: "block",
                                    marginTop: "10px",
                                    color: "red",
                                  }}
                                >
                                  {popUpNoticeImageError}
                                </span>
                              )}
                              {popUpNoticeImage && (
                                <div className="mt-2">
                                  <img
                                    style={{
                                      width: "200px",
                                      height: "150px",
                                    }}
                                    src={popUpNoticeImage}
                                    alt="Card image"
                                  />

                                  <div className="d-flex justify-content-start mt-1">
                                    {" "}
                                    <button
                                      onClick={() => setPopUpNoticeImage(null)}
                                      className="btn btn-danger"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="my-2">
                              <Label htmlFor="formrow-firstname-Input">
                                Header Notice Redirect URL
                              </Label>
                              <Input
                                name="header-redirect"
                                value={headerNoticeRedirectURL}
                                onChange={e => setHeaderNoticeRedirectURL(e.target.value)}
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                              />
                            </div>

                            <div className="my-2">
                              <Label htmlFor="formrow-firstname-Input">
                                Popup Image Redirect URL
                              </Label>
                              <Input
                                name="popup-redirect"
                                value={popupNoticeRedirectURL}
                                onChange={e => setPopupNoticeRedirectURL(e.target.value)}
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                              />
                            </div>

                            <div className="my-2">
                              <h5 className="font-size-14 mb-4">
                                Active Status
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="publish-status"
                                  id="draft"
                                  value="true"
                                  checked={active ? true : false}
                                  onChange={e => setActive(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="draft"
                                >
                                  True
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="publish-status"
                                  id="Publish"
                                  value="false"
                                  checked={!active ? true : false}
                                  onChange={e => setActive(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Publish"
                                >
                                  False
                                </label>
                              </div>
                            </div>

                            <div className="my-2">
                              <h5 className="font-size-14 mb-4">
                                Header Notice Type
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="image-notice-status"
                                  id="draft"
                                  value="text"
                                  checked={imageNotice == 'text' ? true : false}
                                  onChange={e => setImageNotice('text')}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="draft"
                                >
                                  Text
                                </label>
                              </div>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="image-notice-status"
                                  id="Publish"
                                  value="image"
                                  checked={imageNotice == 'image' ? true : false}
                                  onChange={e => setImageNotice('image')}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Publish"
                                >
                                  Image
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="image-notice-status"
                                  id="Publish"
                                  value="none"
                                  checked={imageNotice === 'none' ? true : false}
                                  onChange={e => setImageNotice('none')}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Publish"
                                >
                                  None
                                </label>
                              </div>
                            </div>



                            <div className="my-4">
                              <h5 className="font-size-14 mb-4">
                                Popup notice
                              </h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="popup-notice-status"
                                  id="draft"
                                  value="true"
                                  checked={popupNotice ? true : false}
                                  onChange={e => setPopupNotice(true)}
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="draft"
                                >
                                  True
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="popup-notice-status"
                                  id="Publish"
                                  value="false"
                                  checked={!popupNotice ? true : false}
                                  onChange={e => setPopupNotice(false)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Publish"
                                >
                                  False
                                </label>
                              </div>
                            </div>



                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md mt-3"
                              >
                                Update notice
                              </button>
                            </div>
                          </div>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditNotice.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

EditNotice.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default withTranslation()(EditNotice)
