import React, { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Spinner,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { getAllStudents } from "actions/students"
import { withTranslation } from "react-i18next"

const BundleStudent = props => {
  const [students, setStudents] = useState([])
  const [studentLoading, setStudentLoading] = useState(false)
  const [selectSeminar, setSelectSeminar] = useState(null)
  const [loading, setLoading] = useState(true)
  const [seminarList, setSeminarList] = useState([])
  const [totoalStudent, setTotalStudent] = useState("")
  const [change, setChange] = useState(false)

  const [status, setStatus] = useState(false)

  // Get all seminar
  useEffect(() => {
    fetch(`https://backend.ourprofessors.com/api/bundle/getall`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setSeminarList(data.message)
        setLoading(false)
      })
      .catch(err => console.log(err))
  }, [change])

  const handleStudent = stds => {
    let students_data = []
    stds?.map(std => {
      students_data.push({
        fullName: std.fullName,
        email: std.email,
        phone: std.phoneNumber,
        NID: std.NID,
        _id: std._id,
      })
    })
    setStudents(students_data)
  }

  useEffect(() => {
    setStudentLoading(true)
    fetch(
      `https://backend.ourprofessors.com/new/api/subscription/user/${selectSeminar}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(r => r.json())
      .then(res => {
        setStudentLoading(false)

        handleStudent(res?.data)
        setTotalStudent(res?.data ? res?.data?.length : 0)
      })
      .catch(err => console.log(err))
  }, [selectSeminar, change])

  useEffect(() => {
    setLoading(true)
    {
      selectSeminar
        ? setLoading(false)
        : fetch(
            `https://backend.ourprofessors.com/new/api/subscription/user/${selectSeminar}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
            .then(res => res.json())
            .then(data => {
              if (data?.success) {
                handleStudent(data?.data)
                setTotalStudent(data?.data ? data?.data?.length : 0)
                setStatus(false)
                setLoading(false)
              }
            })
            .catch(err => console.log(err))
    }
  }, [change])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Bundle")}
            breadcrumbItem={props.t("Bundle Students")}
          />

          <div className="mb-3">
            <Label htmlFor="formrow-firstname-Input">
              Select Bundle Course to get specific student list
            </Label>
            <Input
              name="seminar"
              type="select"
              autoComplete="off"
              onChange={e => setSelectSeminar(e.target.value)}
            >
              {seminarList?.map(seminar => {
                return (
                  <>
                    <option key={seminar._id} value="" hidden>
                      Select
                    </option>
                    <option id="seminarTitle" value={seminar._id}>
                      {seminar.name}
                    </option>
                  </>
                )
              })}
            </Input>
            {/* <button
              onClick={() => {
                setSelectSeminar(null)
                setChange(!change)
                setStatus(true)
               
              }}
              style={{
                marginTop: "10px",
                border: "none",
                background: "#2a3042",
                color: "white",
                padding: "5px 10px",
                borderRadius: "4px",
              }}
            >
              Clear Filter
            </button> */}
          </div>

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      {loading ? (
                        <Spinner></Spinner>
                      ) : (
                        <>
                          <div className="d-flex">
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                                paddingBottom: "20px",
                              }}
                            >
                              <h5>
                                Total Student :{" "}
                                {totoalStudent ? totoalStudent : 0}
                              </h5>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                textAlign: "right",
                                paddingBottom: "20px",
                              }}
                            >
                              {students?.length > 0 && (
                                <CSVLink data={students}>Export as csv</CSVLink>
                              )}
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div
                              className="table-responsive mb-0"
                              data-pattern="priority-columns"
                            >
                              <Table className="project-list-table table-nowrap align-middle table-borderless">
                                <thead>
                                  <tr>
                                    <th>Fullname</th>
                                    <th data-priority="1">Email</th>
                                    <th data-priority="3">Mobile Number</th>
                                    <th data-priority="3">NID</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {students?.length > 0 &&
                                    students?.map((student, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>{student.fullName}</td>
                                          <td>{student.email}</td>
                                          <td>{student.phone}</td>
                                          <td>{student.NID}</td>
                                        </tr>
                                      )
                                    })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(BundleStudent)
