import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { Card, CardBody, Col, Container, Input, Label, Row, Form, FormGroup } from "reactstrap"

// Form Editor

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { imageUpload } from "actions/imageUpload"
import FBEditor from "components/Editor/Editor"
import { withTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

//redux

const AddNewCourse = props => {
  /**
   * * State for course data
   */
  const [name, setName] = useState("")
  const [slug, setSlug] = useState("")
  const [description, setDescription] = useState(null)
  const [shortDescription, setShortDescription] = useState(null)
  const [textareabadge, settextareabadge] = useState(0)
  const [textcount, settextcount] = useState(0)
  const [courseDetails, setCourseDetails] = useState(null)
  const [selectCourses, setSelectCourses] = useState([])
  const [courseOption, setCourseOption] = useState([])
  const [finalCourses, setFinalCourses] = useState([])
  const [image, setImage] = useState(null)
  const [uploadStatus, setUploadStatus] = useState(false)
  const [allCourse, setAllCourse] = useState([])
  const [price, setPrice] = useState(0)
  const [profileImageError, setProfileImageError] = useState(null)
  const [benifits, setBenifits] = useState([{ benifit: "" }])
  const history = useHistory()
  const { id } = useParams()
  const [subscription, setSubscription] = useState(false)
  const [courseValidity, setCourseValidity] = useState(0)
  const [enrollOff, setEnrollOff] = useState(false)
  const [cardImage, setCardImage] = useState(null)
  const [cardImageError, setCardImageError] = useState(null)
  const [coursePublish, setCoursePublish] = useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setAllCourse(data?.message)
      })
  }, [])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/bundle/get/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setCourseDetails(data?.message)
      })
  }, [])

  useEffect(() => {
    setName(courseDetails?.name)
    setDescription(
      courseDetails !== null
        ? courseDetails?.description !== null
          ? courseDetails?.description
          : ""
        : ""
    )
    setShortDescription(courseDetails?.short_description)
    setImage(courseDetails?.image)
    setSlug(courseDetails?.slug)
    setPrice(courseDetails?.price)
    setCourseValidity(courseDetails?.validityDays)
    setSubscription(courseDetails?.subscription)

    setEnrollOff(courseDetails?.enrollOff)
    setCardImage(courseDetails?.cardImage)
    setCoursePublish(courseDetails?.course_publish)

    if (courseDetails !== null && courseDetails?.benefits?.length > 0) {
      courseDetails?.benefits?.map((pd, index) => {
        let preBenifit = {}
        preBenifit["benifit"] = pd?.benifit

        // questions[index].push(preQuestion)
        benifits.splice(index, 0, preBenifit)
      })
    }
  }, [courseDetails])

  function handleAcceptedFiles(files) {
    setProfileImageError(null)
    const extension = files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setImage(data?.key)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              setUploadStatus(false)

              toastr.error(data?.message, "Course Image")
            } else {
              setImage(data?.key)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Course Image")
          } else {
            toastr.error(err?.response?.data?.message, "Course Image")
          }
        })
    } else {
      setProfileImageError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }

  function handleAcceptedCardFiles(files) {
    setCardImageError(null)
    const extension = files[0]?.name?.split(".").pop()

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      setUploadStatus(true)
      imageUpload(files[0])
        .then(res => res.json())
        .then(data => {
          if (data?.success === undefined) {
            setCardImage(data?.Location)
            setUploadStatus(false)
          } else {
            if (!data?.success) {
              setUploadStatus(false)

              toastr.error(data?.message, "Course Image")
            } else {
              setCardImage(data?.Location)
              setUploadStatus(false)
            }
          }
        })
        .catch(err => {
          if (err?.response?.data?.message === undefined) {
            toastr.error(err?.response?.data, "Course Image")
          } else {
            toastr.error(err?.response?.data?.message, "Course Image")
          }
        })
    } else {
      setCardImageError("Unknown type! try to select jpg, png or jpeg type!")
    }
  }


  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSubmit = async () => {
    let finalBenifits = []
    benifits.map(benifit => {
      if (benifit.benifit !== "") {
        finalBenifits.push(benifit)
      }
    })
    const body = {
      bundle_name: name,
      slug,
      bundle_description: description,
      bundle_short_description: shortDescription,
      course: finalCourses,
      bundle_image: image,
      price: Number(price),
      benefits: [...finalBenifits],
      validityDays: courseValidity,
      subscription,
      enrollOff,
      cardImage,
      course_publish: coursePublish,
    }

    await fetch(`${process.env.REACT_APP_URL}/api/bundle/put/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res?.json())
      .then(data => {
        if (data?.success) {
          toastr.success(data?.message, "Course")
          history.push("/bundle-courses")
        } else {
          toastr.error(data?.message, "Course")
        }
      })
      .catch(err => toastr.error(err?.response?.data?.message, "Course"))
  }
  const handleBodyChange = e => {
    setDescription(e)
  }

  useEffect(() => {
    courseDetails?.course?.map(course => {
      const findCourse = allCourse?.find(c => c?._id === course)

      if (findCourse !== undefined) {
        setSelectCourses(prevCourse => [
          ...prevCourse,
          { label: findCourse?.title, value: findCourse?._id },
        ])
      }
    })
  }, [courseDetails, allCourse])

  useEffect(() => {
    allCourse?.length > 0 &&
      allCourse?.map(course =>
        setCourseOption(prevCourse => [
          ...prevCourse,
          { label: course?.title, value: course?._id },
        ])
      )
  }, [allCourse])

  useEffect(() => {
    selectCourses?.length > 0 &&
      selectCourses?.map(course => {
        setFinalCourses(setCourse => [...setCourse, course?.value])
      })
  }, [selectCourses])

  const courseName = [
    {
      options: courseOption,
    },
  ]

  function textareachange(event) {
    setShortDescription(event.target.value)
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }

  function handleMulti(e) {
    setSelectCourses(e)
    setFinalCourses([])
  }

  //bundle benefits

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...benifits]
    list[index][name] = value
    setBenifits(list)
  }

  const handleRemoveClick = index => {
    const list = [...benifits]
    list.splice(index, 1)
    setBenifits(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setBenifits([
      ...benifits,
      {
        benifit: "",
      },
    ])
  }

  console.log(benifits)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Qlearn </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Courses")}
            breadcrumbItem={props.t("Add new bundle course")}
          />

          <Row>
            <Col xl="12">
              <Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Bundle Name
                            </Label>
                            <Input
                              value={name}
                              onChange={e => setName(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Course Title"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Slug
                            </Label>
                            <Input
                              required
                              value={slug}
                              onChange={e => setSlug(e.target.value)}
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                          </div>

                          <Row>
                            <Col
                              lg={12}
                              style={{ marginTop: "0px", marginBottom: "10px" }}
                            >
                              <FBEditor
                                htmlData={description}
                                setHtmlData={setDescription}
                                prevData={
                                  courseDetails !== null
                                    ? courseDetails?.description !== null
                                      ? courseDetails?.description
                                      : ""
                                    : ""
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <div className="mt-2 mb-3">
                              <Label>Short Description</Label>

                              <Input
                                type="textarea"
                                id="textarea"
                                value={shortDescription}
                                onChange={e => {
                                  textareachange(e)
                                }}
                                maxLength="225"
                                rows="3"
                                placeholder="Short description has a limit of 225 chars."
                              />
                              {textareabadge ? (
                                <span className="badgecount badge bg-success">
                                  {" "}
                                  {textcount} / 225{" "}
                                </span>
                              ) : null}
                            </div>
                          </Row>

                          {benifits?.map((x, i) => {
                            return (
                              <div key={i} className="box ">
                                {/* <p className="me-3 mt-3 "></p> */}
                                <Form>
                                  <FormGroup>
                                    <Label
                                      className="mt-3 fw-bold"
                                      for="Question"
                                    >
                                      Benifit NO : {i + 1}
                                    </Label>
                                    <Input
                                      name="benifit"
                                      placeholder="Course Benifit"
                                      value={x.benifit}
                                      onChange={e => handleInputChange(e, i)}
                                    />
                                  </FormGroup>
                                </Form>
                                <br />
                                <div>
                                  {benifits.length !== 1 && (
                                    <button
                                      // className="deleteButton"
                                      // onClick={() => handleRemoveClick(i)}
                                      onClick={() =>
                                        window.confirm(
                                          "Do you want to delete this benifit?"
                                        )
                                          ? handleRemoveClick(i)
                                          : null
                                      }
                                      className="btn btn-danger w-md"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      <span>Delete this benifit</span>
                                    </button>
                                  )}
                                  {benifits.length - 1 === i && (
                                    <button
                                      className={
                                        benifits.length == 1
                                          ? "addButton"
                                          : "addButton ms-3"
                                      }
                                      onClick={handleAddClick}
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      <span>Add New benifit</span>
                                    </button>
                                  )}
                                </div>
                              </div>
                            )
                          })}



                          <div className="mb-3">
                            <Label>Select Courses</Label>
                            <Select
                              value={selectCourses}
                              isMulti={true}
                              onChange={data => {
                                handleMulti(data)
                              }}
                              options={courseName}
                              classNamePrefix="select2-selection"
                            />
                          </div>

                          <div>
                            <label htmlFor="">Upload Featured Image</label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                          {profileImageError && (
                            <span
                              style={{
                                display: "block",
                                marginTop: "10px",
                                color: "red",
                              }}
                            >
                              {profileImageError}
                            </span>
                          )}

                          <div className="mt-3">
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={
                                image
                                  ? `${process.env.REACT_APP_URL}/api/image/download/${image}`
                                  : ""
                              }
                              alt=""
                            />
                          </div>

                          <div className="mt-2">
                            <label htmlFor="">Upload Card Image</label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedCardFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input required {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            {cardImageError && (
                              <span
                                style={{
                                  display: "block",
                                  marginTop: "10px",
                                  color: "red",
                                }}
                              >
                                {cardImageError}
                              </span>
                            )}
                            {cardImage && (
                              <div className="mt-2">
                                <img
                                  style={{
                                    width: "200px",
                                    height: "150px",
                                  }}
                                  src={cardImage}
                                  alt="Card image"
                                />

                                <div className="d-flex justify-content-start mt-1">
                                  {" "}
                                  <button
                                    onClick={() => setCardImage(null)}
                                    className="btn btn-danger"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>


                          <div className="mt-3 mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Bundle Price
                            </Label>
                            <Input
                              onWheel={e => e.target.blur()}
                              value={price}
                              onChange={e => setPrice(e.target.value)}
                              type="number"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Course Price"
                            />
                          </div>


                          <div className="mt-3 mb-3">
                            <h5 className="font-size-14 mb-4">
                              Subscription Status
                            </h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="publish-status"
                                id="draft"
                                value="true"
                                checked={subscription ? true : false}
                                onChange={e => setSubscription(true)}

                              />
                              <label
                                className="form-check-label"
                                htmlFor="draft"
                              >
                                True
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="publish-status"
                                id="Publish"
                                value="false"
                                checked={!subscription ? true : false}
                                onChange={e => setSubscription(false)}

                              />
                              <label
                                className="form-check-label"
                                htmlFor="Publish"
                              >
                                False
                              </label>
                            </div>
                          </div>

                          <div className="mt-3 mb-3">
                            <h5 className="font-size-14 mb-4">
                              Enroll Off
                            </h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="enroll-status"
                                id="draft"
                                value="true"
                                checked={enrollOff ? true : false}
                                onChange={e => setEnrollOff(true)}

                              />
                              <label
                                className="form-check-label"
                                htmlFor="draft"
                              >
                                True
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="enroll-status"
                                id="Publish"
                                value="false"
                                checked={!enrollOff ? true : false}
                                onChange={e => setEnrollOff(false)}

                              />
                              <label
                                className="form-check-label"
                                htmlFor="Publish"
                              >
                                False
                              </label>
                            </div>
                          </div>

                          <div className="mt-3 mb-3">
                            <h5 className="font-size-14 mb-4">
                              Course Publish
                            </h5>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="course-publish-status"
                                id="draft"
                                value="true"
                                checked={coursePublish ? true : false}
                                onChange={e => setCoursePublish(true)}

                              />
                              <label
                                className="form-check-label"
                                htmlFor="draft"
                              >
                                True
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="course-publish-status"
                                id="Publish"
                                value="false"
                                checked={!coursePublish ? true : false}
                                onChange={e => setCoursePublish(false)}

                              />
                              <label
                                className="form-check-label"
                                htmlFor="Publish"
                              >
                                False
                              </label>
                            </div>
                          </div>
                          <div className="mt-3 mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Course Validity (days)
                            </Label>
                            <Input
                              min="0"
                              onWheel={e => e.target.blur()}
                              value={courseValidity}
                              onChange={e => setCourseValidity(e.target.value)}
                              type="number"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Course Validity"
                            />
                          </div>

                          <div>
                            <button
                              disabled={uploadStatus ? true : false}
                              type="submit"
                              onClick={handleSubmit}
                              className="btn btn-primary w-md mt-3"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddNewCourse.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
}

AddNewCourse.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
]

export default withTranslation()(AddNewCourse)
